/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { bindKeyboard } from "react-swipeable-views-utils";

import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";

const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

const Carousel = ({
  children,
  startIndex,
}: {
  children: JSX.Element | JSX.Element[];
  startIndex?: number;
}) => {
  const [activeStep, setActiveStep] = useState(startIndex ?? 0);
  const childrenArray = Array.isArray(children) ? children : [children];
  return (
    <div tw="flex items-center flex-col gap-2">
      <BindKeyboardSwipeableViews
        onChangeIndex={setActiveStep}
        index={activeStep}
        enableMouseEvents
      >
        {childrenArray}
      </BindKeyboardSwipeableViews>
      {childrenArray.length > 1 && (
        <div tw="flex justify-between w-full max-w-sm">
          <IconButton
            onClick={() => setActiveStep(activeStep - 1)}
            disabled={activeStep === 0}
          >
            <ArrowBackIosNew />
          </IconButton>
          <MobileStepper
            tw="bg-transparent"
            steps={childrenArray.length}
            position="static"
            activeStep={activeStep}
            backButton={<button />}
            nextButton={<button />}
          />
          <IconButton
            onClick={() => setActiveStep(activeStep + 1)}
            disabled={activeStep === childrenArray.length - 1}
          >
            <ArrowForwardIos />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default Carousel;
