/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views-react-18-fix";

import { DownloadRounded, OpenInNewRounded } from "@mui/icons-material";
import { IconButton, MobileStepper, Tooltip } from "@mui/material";

import { getCloudinaryFileUrl } from "@features/cloudinaryFiles";
import { CloudinaryFile } from "@models/CloudinaryFile";
import cloudinary, { CldImage, imageSizeOptions } from "@services/cloudinary";

const Slide = ({ file }: { file: CloudinaryFile }) => {
  const [activeStep, setActiveStep] = useState(0);

  const imageUrls = Array(file.pdfPageCount || 1)
    .fill(null)
    .map((_, i) =>
      cloudinary.url(file.cloudinaryId, {
        resource_type: "image",
        page: i + 1,
        format: "jpg",
        ...imageSizeOptions.large,
      })
    );

  const downloadUrl = getCloudinaryFileUrl(file);
  const viewUrl = getCloudinaryFileUrl(file, { forDownload: false });

  return (
    <div tw="space-y-3">
      <SwipeableViews
        onChangeIndex={setActiveStep}
        index={activeStep}
        enableMouseEvents
      >
        {imageUrls.map((url) => (
          <div key={url} tw="flex justify-center p-4">
            <CldImage
              src={url}
              tw="pointer-events-none select-none rounded shadow-lg max-h-[80vh]"
            />
          </div>
        ))}
      </SwipeableViews>
      {imageUrls.length > 1 && (
        <MobileStepper
          tw="bg-transparent"
          steps={imageUrls.length}
          position="static"
          activeStep={activeStep}
          backButton={<button />}
          nextButton={<button />}
        />
      )}
      <div tw="self-stretch flex gap-2 items-center justify-center text-sm">
        <Tooltip title="Download File">
          <IconButton size="small" href={downloadUrl} edge="start">
            <DownloadRounded fontSize="small" />
          </IconButton>
        </Tooltip>
        {file.resourceType === "image" && (
          <Tooltip title="View original in new tab">
            <IconButton
              size="small"
              href={viewUrl}
              target="_blank"
              edge="start"
            >
              <OpenInNewRounded fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        <div tw="select-text">
          {file.fileName}.{file.format}
        </div>
        {file.pdfPageCount > 0 && (
          <div tw="text-neutral-500">
            ({file.pdfPageCount} page{file.pdfPageCount > 1 ? "s" : ""})
          </div>
        )}
      </div>
    </div>
  );
};

const ConceptImageCarousel = ({ files }: { files: CloudinaryFile[] }) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setActiveStep(0);
  }, [files]);

  return (
    <div tw="grid grid-cols-[auto_1fr] gap-4">
      <div tw="flex flex-col gap-4 pt-4">
        {files.map((file, i) => (
          <button
            key={file.id}
            css={[
              tw`w-24 h-24 overflow-hidden rounded-lg shadow-md lg:w-32 lg:h-32`,
              activeStep === i && tw`outline outline-2 outline-primary-600`,
            ]}
            onClick={() => setActiveStep(i)}
          >
            <CldImage
              publicId={file.cloudinaryId + ".jpg"}
              size="medium"
              tw="w-full h-full object-cover pointer-events-none select-none"
            />
          </button>
        ))}
      </div>
      <div tw="flex items-center justify-center">
        <Slide file={files[activeStep]} />
      </div>
    </div>
  );
};

export default ConceptImageCarousel;
